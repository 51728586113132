<template>
  <form ref="newsletterSubscriber">
    <CRow>
      <CCol sm="6">
        <CInput
            label="Vorname"
            name="firstName"
            v-model="firstName"
            description="Bitte geben Sie den Vornamen des Abonennten ein."
            required
        />
      </CCol>
      <CCol sm="6">
        <CInput
            label="Nachname"
            name="lastName"
            v-model="lastName"
            description="Bitte geben Sie den Nachnamen des Abonennten ein."
            required
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
            label="E-Mail"
            name="email"
            v-model="email"
            description="Bitte geben Sie die E-Mail Adresse des Abonennten ein."
            required
        />
      </CCol>
      <CCol sm="4">
        <NewsletterGroupSelector
            v-model="groups"
            description="Hier können Sie die Zielgruppe(n) auswählen, die diesem Abonnenten zugewiesen werden soll(en). Wichtig: Wenn keine Zielgruppe ausgewählt ist, bekommen dieser Abonnent alle Newsletter/Funnels die Sie verfassen."
        />
      </CCol>
      <CCol sm="2">
        <label for="verified">Abonnent verifiziert?</label>
        <CInputCheckbox
            label="Verifiziert?"
            id="verified"
            name="verified"
            value="Ja"
            :custom="true"
            :checked.sync="verified"
            :inline="false"
        />
        <small class="form-text text-muted w-100">Nur verifizierte Abonnenten (E-Mail Adresse bestätigt) erhalten E-Mails. Ist das Häckchen nicht aktiv, wurde die E-Mail Adresse nicht bestätigt.</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary">
          Processing...
        </CButton>
        <CButton v-if="enableDelete" @click="deleteHandler" color="danger" style="margin-left: 4px">
          Löschen
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import WysiwygEditor from '@/components/WysiwygEditor'
import Checkbox from '@/components/Checkbox'
import axios from 'axios'
import moment from "moment";
import DateTimePicker from "@/components/DateTimePicker";
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";
import UnlayerEmailEditor from "@/components/UnlayerEditorWrapper";

export default {
  name: 'NewsletterSubscriberForm',
  components: { UnlayerEmailEditor, DateTimePicker, WysiwygEditor, Checkbox, NewsletterGroupSelector },
  props: {
    passedData: {
      type: Object,
      default: () => ( { id: 0 } )
    },
    btnText: {
      type: String,
      required: true
    },
    enableDelete: Boolean,
    processing: Boolean
  },
  data() {
    return {
      id: this.passedData.id || 0,
      realmId: (this.passedData.realm || {}).id || this.passedData.realmId || 1,
      firstName: this.passedData.firstName || '',
      lastName: this.passedData.lastName || '',
      email: this.passedData.email || '',
      verified: typeof this.passedData.verified == 'boolean' ? this.passedData.verified : true,
      groups: this.passedData.groups || []
    }
  },
  methods: {
    clickHandler() {
      if (this.$refs.newsletterSubscriber.checkValidity()) {
        this.$emit('submit', { ...this.$data })
      } else {
        this.$refs.newsletterSubscriber.reportValidity()
      }
    },
    deleteHandler() {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>NewsletterSubscriber</b> (${this.passedData.email})`],
          async () => {
            try {
              let resp = await axios.delete(`/emailBasic/subscriber/${this.id}`)
              this.$toaster.makeToast('Erfolgreich!', 'Der Abonnent wurde erfolgreich gelöscht.')
              this.$router.push({name: 'NewsletterSubscribers', params: { saved: '1' }})
            } catch (e) {
              this.$toaster.makeToast('Fehler!', 'Der Abonnent konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')
            }
          }
      )
    }
  }
}
</script>
